import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { toast } from 'react-toastify';

interface ToastOptions {
  position?:
    | 'top-right'
    | 'top-center'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-center'
    | 'bottom-left';
  autoClose?: number | false | undefined;
  hideProgressBar?: boolean | undefined;
  closeOnClick?: boolean | undefined;
  pauseOnHover?: boolean | undefined;
  draggable?: boolean | undefined;
  progress?: string | number | undefined;
}

const helperFunctions = {
  showToast: (
    messageToShow: string,
    toastType: 'success' | 'info' | 'error' | 'warning' | 'default' | 'dark',
    toasterOptions?: ToastOptions
  ) => {
    const toasterOptionsModified = toasterOptions;
    if (toasterOptionsModified && !toasterOptionsModified.position) {
      toasterOptionsModified.position = 'top-right';
    }
    if (toasterOptionsModified && !toasterOptionsModified.autoClose) {
      toasterOptionsModified.autoClose = 2000;
    }
    if (toasterOptionsModified && !toasterOptionsModified.pauseOnHover) {
      toasterOptionsModified.pauseOnHover = false;
      toasterOptionsModified.hideProgressBar = false;
    }
    toast.dismiss();
    switch (toastType) {
      case 'success':
        toast.success(
          <>
            {' '}
            <div className="toast-position">
              <CheckCircleIcon /> <p>{messageToShow}</p>
            </div>{' '}
            {toasterOptionsModified}
          </>
        );
        break;
      case 'error':
        toast.error(
          <>
            {' '}
            <div className="toast-position">
              <CancelIcon /> <p>{messageToShow}</p>
            </div>{' '}
            {toasterOptionsModified}
          </>
        );
        break;
      case 'info':
        toast.info(
          <>
            {' '}
            <div className="toast-position">
              <InfoIcon /> <p>{messageToShow}</p>
            </div>{' '}
            {toasterOptionsModified}
          </>
        );
        break;
      case 'warning':
        toast.warn(
          <>
            {' '}
            <div className="toast-position">
              <WarningIcon /> <p>{messageToShow}</p>
            </div>{' '}
            {toasterOptionsModified}
          </>
        );
        break;
      case 'dark':
        toast.dark(messageToShow, toasterOptionsModified);
        break;
      case 'default':
        toast(messageToShow, toasterOptionsModified);
        break;
    }
  },
};

export default helperFunctions;
